<!-- 全职职位发布 -->
<template>
  <div class="job_full">
    <header>
      <Button size="small" type="warning" @click="$router.back()">
        <Icon type="ios-arrow-back" />
        返回
      </Button>
      <Divider type="vertical" />
      <p style="font-size:18px">{{this.$route.meta.title}}</p>
    </header>
    <section>
      <Spin size="large" fix v-if="spinShow"></Spin>
      <div class="content">
        <Form ref="formValidate" :model="formValidate" :rules="ruleValidate" :label-width="100">

          <FormItem label="职位名称" prop="name">
            <Input v-model="formValidate.name" maxlength="8" placeholder="职位名称(长度8)"></Input>
          </FormItem>

          <FormItem label="分类" prop="category">
            <Select v-model="formValidate.category" placeholder="分类">
              <Option :value="item.id" v-for="item in Option" :key="item.id">{{item.name}}</Option>

            </Select>
          </FormItem>

          <FormItem label="有效期" prop="indate">
            <Select v-model="formValidate.indate" placeholder="有效期">
              <Option :value="item.id" v-for="item in indate" :key="item.id">{{item.name}}</Option>
            </Select>
          </FormItem>

          <FormItem label="是否急聘">
            <RadioGroup v-model="formValidate.openLevel" @on-change="openLevel_change">
              <Radio label="1">是</Radio>
              <Radio label="2">否</Radio>
            </RadioGroup>
          </FormItem>

          <FormItem label="招聘人数" prop="need_number">
            <Input v-model="formValidate.need_number" placeholder="招聘人数"></Input>
          </FormItem>

          <!-- <FormItem label="性别" prop="gender">
            <Select v-model="formValidate.gender" placeholder="性别">
              <Option :value="item.id" v-for="item in gender" :key="item.id">{{item.name}}</Option>
            </Select>
          </FormItem> -->

          <!-- <FormItem label="年龄" prop="age">
            <Input v-model="formValidate.age" placeholder="年龄，例如18~45岁"></Input>
          </FormItem> -->

          <FormItem label="学历要求">
            <Select v-model="formValidate.education" placeholder="学历要求">
              <Option :value="item.id" v-for="item in education" :key="item.id">{{item.name}}</Option>
            </Select>
          </FormItem>

          <FormItem label="工作经验要求">
            <Select v-model="formValidate.minExperience" placeholder="工作经验要求">
              <Option :value="item.id" v-for="item in minExperience" :key="item.id">{{item.name}}</Option>
            </Select>
          </FormItem>

          <FormItem label="薪酬范围" prop="salary">
            <RadioGroup v-model="formValidate.salary" style="width:100%">
              <div style="display:flex;width:100%">
                <div style="width:50%;display:flex;flex-direction:column;">
                  <Radio label="3500-5000">3500-5000元/月</Radio>
                  <Radio label="4500-7000">4500-7000元/月</Radio>
                  <Radio label="10000以上">10000以上</Radio>
                </div>
                <div style="width:50%;display:flex;flex-direction:column;">
                  <Radio label="4000-6000">4000-6000元/月</Radio>
                  <Radio label="7000-10000">7000-10000元/月</Radio>
                  <Radio label="高薪面议">高薪面议</Radio>
                </div>
              </div>

              <Radio :label="min_Salary+'-'+max_Salary">
                自定义
                <Input v-model="min_Salary" style="width:80px" @on-blur="compare"></Input>
                -
                <Input v-model="max_Salary" style="width:80px" @on-blur="compare"></Input>
                元/月
              </Radio>
            </RadioGroup>
          </FormItem>

          <FormItem label="技能要求">
            <CheckboxGroup v-model="otherask" style="width:100%">
              <div style="display:flex;width:100%">
                <div style="width:25%;min-width:80px;display:flex;flex-direction:column;">
                  <Checkbox label="叉车证"></Checkbox>
                  <Checkbox label="驾驶证"></Checkbox>
                </div>
                <div style="width:25%;min-width:80px;display:flex;flex-direction:column;">
                  <Checkbox label="电工证"></Checkbox>
                  <Checkbox label="低压证"></Checkbox>
                </div>
                <div style="width:25%;min-width:80px;display:flex;flex-direction:column;">
                  <Checkbox label="焊工证"></Checkbox>
                  <Checkbox label="保安证"></Checkbox>
                </div>
                <div style="width:25%;min-width:80px;display:flex;flex-direction:column;">
                  <Checkbox label="钳工证"></Checkbox>
                  <Checkbox label="退伍证"></Checkbox>
                </div>
              </div>

            </CheckboxGroup>

            <Checkbox v-model="check_skill">
              其他技能
              <Input v-model="other_skills" style="width:80px"></Input>
            </Checkbox>

          </FormItem>

          <FormItem label="福利待遇">
            <CheckboxGroup v-model="welfare" style="width:100%">
              <div style="display:flex;width:100%">
                <div style="width:30%;min-width:120px;display:flex;flex-direction:column;">
                  <Checkbox label="五险一金"></Checkbox>
                  <Checkbox label="绩效奖金"></Checkbox>
                  <Checkbox label="工作餐"></Checkbox>
                  <Checkbox label="培训"></Checkbox>
                  <Checkbox label="住房补贴"></Checkbox>
                  <Checkbox label="厂车接送"></Checkbox>
                </div>
                <div style="width:30%;min-width:120px;display:flex;flex-direction:column;">
                  <Checkbox label="双休"></Checkbox>
                  <Checkbox label="法定节假日"></Checkbox>
                  <Checkbox label="住宿"></Checkbox>
                  <Checkbox label="包吃住"></Checkbox>
                  <Checkbox label="年度旅游"></Checkbox>
                  <Checkbox label="年终奖"></Checkbox>
                </div>
                <div style="width:30%;min-width:120px;display:flex;flex-direction:column;">
                  <Checkbox label="带薪年假"></Checkbox>
                  <Checkbox label="交通补贴"></Checkbox>
                  <Checkbox label="餐补"></Checkbox>
                  <Checkbox label="包吃"></Checkbox>
                  <Checkbox label="长白班"></Checkbox>
                  <Checkbox label="免费体检"></Checkbox>
                </div>
              </div>

            </CheckboxGroup>

            <Checkbox v-model="check_welfare">
              其他福利
              <Input v-model="other_welfare" style="width:80px"></Input>
            </Checkbox>
          </FormItem>

          <!-- <FormItem label="所属片区" prop="workArea">
            <Cascader :data="workArea_data" trigger="hover" v-model="formValidate.workArea"></Cascader>
          </FormItem>

          <FormItem label="所属街道" prop="belongingStreet" v-if="formValidate.workArea[1] == '黄埔区'">
            <Select v-model="formValidate.belongingStreet" style="width:200px">
              <Option v-for="item in cityList" :value="item" :key="item">{{ item }}</Option>
            </Select>
          </FormItem> -->

          <strong style="margin-left:70px;font-size:18px">区内企业选择</strong>

          <div style="background:#FFF4EA;padding:10px 0;margin-bottom:15px">
            <FormItem label="所属片区" prop="workArea" :rules="formValidate.workArea[1] == '黄埔区'?ruleValidate.workArea:[{required:false}]">
              <RadioGroup v-model="formValidate.workArea[2]" @on-change='district_change'>
                <Radio style="min-width:20%" v-for="(item,index) in district" :key="index" :label="item.name"></Radio>
              </RadioGroup>
            </FormItem>

            <FormItem label="所属街道" prop="belongingStreet" :rules="formValidate.workArea[1] != '黄埔区'?[{required:false}]:ruleValidate.belongingStreet">
              <RadioGroup v-model="formValidate.belongingStreet">
                <Radio v-for="(item,index) in cityList" :key="index" :label="item"></Radio>
              </RadioGroup>
            </FormItem>
          </div>

          <strong style="margin-left:70px;font-size:18px">区外企业选择</strong>

          <div style="background:#FFF4EA;">
            <FormItem label="所属片区" style="padding:20px 0" prop="workArea" :rules="formValidate.workArea[1] != '黄埔区'?ruleValidate.workArea:[{required:false}]">
              <Cascader :data="workArea_data" trigger="hover" v-model="formValidate.new_workArea" style="width: 200px;" :clearable="false" @on-change='new_street_change'></Cascader>
            </FormItem>
          </div>

          <p style="margin-bottom:20px;font-size:14px;color:rgb(255, 131, 0);text-align:center">请企业按照自身情况在以上两个选项中选择其中一个进行填写</p>

          <FormItem label="工作地点" prop="address">
            <div class="address">
              <Input v-model="formValidate.address" style="width:90%" placeholder="请点击右边图标选择工作地点"></Input>
              <div style="width:10%">
                <img @click="open_Modal" src="@/assets/images/address.png" alt="">
              </div>

            </div>

          </FormItem>

          <FormItem label="职位描述" prop="description">
            <Input v-model="formValidate.description" type="textarea" :autosize="{minRows: 5,}" placeholder="职位描述"></Input>
          </FormItem>

          <!-- !!!!!!!!!!! -->

          <FormItem style="text-align:right">
            <Button type="warning" @click="handleSubmit('formValidate')">{{$route.query.id?'保存修改':'提交发布'}}</Button>
            <!-- <Button @click="handleReset('formValidate')" style="margin-left: 8px">Reset</Button> -->
          </FormItem>

        </Form>
      </div>

    </section>

    <Modal v-model="address_model" title="地图选点" :styles="{top: '20px'}" footer-hide>
      <iframe id="mapPage" width="100%" height="800px" frameborder=0 src="https://apis.map.qq.com/tools/locpicker?search=1&type=1&key=4YYBZ-BIGCG-IMDQT-IOPQG-R77A6-KOFNR&referer=myapp">
      </iframe>
    </Modal>

  </div>
</template>

<script>
import "./hackmap/KOFNR"
import "./hackmap/geolocation"
import { data } from "./job_full"
import { district } from "@/libs/public"
export default {
  data() {
    return {
      //片区
      district,
      spinShow: false,
      Option: data.Option,
      indate: data.indate,
      // gender: data.gender,
      education: data.education,
      minExperience: data.minExperience,
      workArea_data: data.workArea_data,

      //技能
      otherask: [],
      check_skill: false,
      other_skills: '',
      //福利
      welfare: [],
      check_welfare: false,
      other_welfare: '',



      //最低薪酬
      min_Salary: '',
      //最大薪酬
      max_Salary: '',




      formValidate: {
        address: '',
        openLevel: "2",
        // otherask: [],
        // welfare: []
        workArea: ["广州市", "黄埔区", "东区"],
        new_workArea: [],
        belongingStreet: "云埔街道",
        gps: {
          x: 1,
          y: 1
        }
      },
      ruleValidate: {
        name: [
          { required: true, message: '请填写职位名称', trigger: 'blur' }
        ],
        category: [
          { required: true, message: '请选择分类', trigger: 'change' }
        ],
        indate: [
          { required: true, message: '请选择有效期', trigger: 'change' }
        ],
        need_number: [
          { required: true, message: '请填写招聘人数', trigger: 'blur' }
        ],

        // gender: [
        //   { required: true, message: '请选择性别', trigger: 'change' }
        // ],
        // age: [
        //   { required: true, message: '请填写年龄', trigger: 'blur' }
        // ],
        salary: [
          { required: true, message: '请选择薪酬范围', trigger: 'blur' }
        ],
        workArea: [
          { required: true, message: '请选择所属地区', }
        ],
        belongingStreet: [
          { required: true, message: '请填写所属街道', trigger: 'change' }
        ],
        description: [
          { required: true, message: '请填写职位描述', trigger: 'blur' },
        ],
        address: [
          { required: true, message: '请选择工作地点' }
        ],

      },

      //Modal
      address_model: false,
      //地图数据

    }
  },
  computed: {
    cityList() {
      let val = this.formValidate.workArea[2]
      let arr = val == '东区' ? ['云埔街道', '南岗街道'] : val == '西区' ? ['穗东街道', '夏港街道'] : val == '科学城' ? ['联和街道', '萝岗街道', '长岭街道'] : val == '永和' ? ['永和街道', '永宁街道'] : val == '知识城' ? ['龙湖街道', '新龙街道', '九佛街道'] : val == '老黄埔' ? ['文冲街道', '大沙街道', '红山街道'] : val == '区内其他' ? ['鱼珠街道', '长洲街道', '其他街道'] : []
      return arr
    }
  },
  props: ["firm_data"],
  methods: {
    //是否急聘改变时
    openLevel_change(val) {
      console.log(val);
      console.log(this.formValidate.openLevel);
      if ((this.firm_data.type == 1 || this.firm_data.type == 0) && this.formValidate.openLevel == "1") { //如果想跳转成急聘(1)
        this.$Modal.warning({
          title: "请联系客服开通权限"
        })
        this.$nextTick(() => {
          this.formValidate.openLevel = "2"
        })
      }

    },
    district_change(val) {
      this.formValidate.workArea = []
      this.formValidate.new_workArea = []
      this.formValidate.workArea[0] = '广州市'
      this.formValidate.workArea[1] = '黄埔区'
      this.formValidate.workArea[2] = val
      this.formValidate.belongingStreet = ''

    },
    new_street_change(val1) {
      console.log(val1);
      this.formValidate.workArea = val1
    },
    //自定义薪酬失焦后对比
    compare() {
      console.log(this.min_Salary);
      console.log(this.max_Salary);
      if (this.min_Salary && this.max_Salary) {
        if (+this.min_Salary > +this.max_Salary) {
          this.$Modal.warning({
            title: "自定义薪酬最低薪资不可大于最大薪资"
          })
        }
      }
    },

    //地图选点返回事件
    onMessage(event) {
      let loc = event.data
      if (loc && loc.module == 'locationPicker') {
        //防止其他应用也会向该页面post信息，需判断module是否为
        console.log(loc);
        //选点完毕关闭Modal
        this.address_model = false
        this.formValidate.address = loc.poiaddress + loc.poiname
        console.log(this.formValidate);
        this.formValidate.gps.x = loc.latlng.lng
        this.formValidate.gps.y = loc.latlng.lat
      }
    },
    //打开地图选点
    open_Modal() {
      this.address_model = true
    },


    handleSubmit(name) {

      this.$refs[name].validate((valid) => {

        if (valid) {
          //薪酬范围 - 自定义 (验证)
          if (this.formValidate.salary != '3500-5000' &&
            this.formValidate.salary != '4000-6000' &&
            this.formValidate.salary != '4500-7000' &&
            this.formValidate.salary != '7000-10000' &&
            this.formValidate.salary != '10000以上' &&
            this.formValidate.salary != '高薪面议'
          ) {
            //自定义薪酬

            if (this.min_Salary && this.max_Salary) {
              if (this.min_Salary >= 3000) {
                if (+this.min_Salary <= +this.max_Salary) {
                  this.formValidate.salary = this.min_Salary + '-' + this.max_Salary
                } else {
                  this.$Modal.warning({
                    title: "自定义薪酬最低薪资不可大于最大薪资"
                  })
                  return false
                }

              } else {
                this.$Modal.warning({
                  title: "自定义薪酬不可少于3000"
                })
                return false
              }
            } else {
              this.$Modal.warning({
                title: "自定义薪酬不能为空"
              })
              return false
            }
          }




          //组合其他技能数组
          let new_skills = JSON.parse(JSON.stringify(this.otherask))
          if (this.check_skill && this.other_skills) {
            new_skills.push('')
            new_skills.push(this.other_skills)
          }
          this.formValidate.otherask = new_skills



          //组合其他福利数组
          let new_welfare = JSON.parse(JSON.stringify(this.welfare))
          if (this.check_welfare && this.other_welfare) {
            new_welfare.push('')
            new_welfare.push(this.other_welfare)
          }
          this.formValidate.welfare = new_welfare



          console.log("结束");
          console.log(this.formValidate.otherask);
          console.log(this.formValidate.welfare);
          this.formValidate.mobile = localStorage.getItem('firm_mobile')

          if (this.$route.query.id) {

            //修改
            this.$Modal.confirm({
              title: "该职位已发布，修改职位后需重新审核",
              closable: true,
              onOk: () => {
                this.formValidate._id = this.$route.query.id
                this.spinShow = true
                this.app("enterpriseOperators", "updateFulltimePosition", this.formValidate).then(res => {
                  console.log(res);
                  this.$Message.warning({
                    content: res.message,
                    duration: 4
                  })
                  if (res.code == 200) {
                    this.$router.push({ name: 'job_manage' })
                  }
                  this.spinShow = false
                })
              }
            })



          } else {
            //发布新职位

            console.log(this.formValidate);
            if (!this.formValidate.gps || this.formValidate.gps.x == 1) {
              this.$Modal.warning({
                title: "请先地图定位工作地点"
              })

            } else {
              this.spinShow = true
              this.app("enterpriseOperators", "addFulltimePosition", this.formValidate).then(res => {
                this.$Modal.warning({
                  title: res.message
                })
                if (res.code == 200) {
                  this.$router.push({ name: 'job_manage' })
                }
                this.spinShow = false
              })
            }

          }


        } else {
          this.$Message.error('请填写齐全资料');
        }
      })
    },
    // handleReset(name) {
    //   this.$refs[name].resetFields();
    // },
    //封装获取数据
    get_details() {
      this.spinShow = true
      this.app("enterpriseOperators", "getPosition", {
        _id: this.$route.query.id
      }).then(res => {
        console.log(res);
        this.formValidate = res.result
        this.formValidate.new_workArea = this.formValidate.workArea
        if (res.result.otherask[0]) {
          let bool_otherask = true
          res.result.otherask.map((item, index) => {
            if (!item) {
              this.check_skill = true
              this.otherask = res.result.otherask.slice(0, index)
              this.other_skills = res.result.otherask[index + 1]
              bool_otherask = false
            }
          })
          if (bool_otherask) {
            this.otherask = res.result.otherask
          }

        } else if (res.result.otherask.length > 0) {
          this.check_skill = true
          this.other_skills = res.result.otherask[1]
          this.formValidate.otherask = []
        }
        if (res.result.welfare[0]) {

          let bool_welfare = true
          res.result.welfare.map((item, index) => {
            if (!item) {
              this.check_welfare = true
              this.other_welfare = res.result.welfare[index + 1]
              this.welfare = res.result.welfare.slice(0, index)
              bool_welfare = false
            }
          })
          if (bool_welfare) {
            this.welfare = res.result.welfare
          }
        } else if (res.result.welfare.length > 0) {
          this.check_welfare = true
          this.other_welfare = res.result.welfare[1]
          this.formValidate.welfare = []
        }
        //截取薪酬范围
        if (this.formValidate.salary != '3500-5000' &&
          this.formValidate.salary != '4000-6000' &&
          this.formValidate.salary != '4500-7000' &&
          this.formValidate.salary != '7000-10000' &&
          this.formValidate.salary != '10000以上' &&
          this.formValidate.salary != '高薪面议'
        ) {
          this.min_Salary = this.formValidate.salary.split('-')[0]
          this.max_Salary = this.formValidate.salary.split('-')[1]
        }

        console.log(this.firm_data);
        //重新发布时如果不是会员是否急聘跳回否（因没有权限）
        if (this.firm_data.type == 1 || this.firm_data.type == 0) {
          this.$nextTick(() => {
            this.formValidate.openLevel = "2"
          })
        }
        this.formValidate.gender = this.formValidate.gender + ''
        this.spinShow = false
      })
    }
  },
  mounted() {
    //接收来自其他页面的消息并判断发送者
    window.addEventListener("message", this.onMessage);
    console.log(this.firm_data);
  },
  created() {

    if (this.$route.query.id) {
      this.get_details()
    } else {

      this.formValidate.address = localStorage.getItem("firm_address")
      if (JSON.parse(localStorage.getItem("firm_gps"))) {
        this.formValidate.gps = JSON.parse(localStorage.getItem("firm_gps"))
      }
      // this.formValidate.new_workArea = JSON.parse(localStorage.getItem("firm_street"))
      // this.formValidate.workArea = JSON.parse(localStorage.getItem("firm_street"))
      // this.formValidate.belongingStreet = JSON.parse(localStorage.getItem("firm_belongingStreet"))
      console.log(JSON.parse(localStorage.getItem("firm_street")));
    }

  }
}
</script>

<style lang="less" scoped>
@import "./job_full.less";
</style>
